import React, { Component } from 'react'
import { css } from '@emotion/react'

class InitialLoader extends Component {
  constructor (props) {
    super(props)

    this.container = React.createRef()
  }

  componentDidMount () {
    console.log(this.container)
  }

  render () {
    const containerCss = () => {
      return css`
        .spinner {
          position: absolute;
          z-index: 100000;
          top: 50%;
          left: 50vw;
          margin: -70px 0 0 -70px;
          width: 140px;
          height: 140px;
          .init-load & {
            animation: rotate 2s linear infinite;
          }
        }
        & .path {
          stroke-linecap: round;
          .init-load & {
            animation: dash 1.5s ease-in-out infinite;
          }
        }

        @keyframes rotate {
          100% {
            transform: rotate(360deg);
          }
        }

        @keyframes dash {
          0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
          }
          50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
          }
          100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
          }
        }
      `
    }

    return (
      <div css={containerCss}>
        <svg className='spinner' viewBox='0 0 50 50'>
          <defs>
            <linearGradient id='gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
              <stop offset='0%' stopColor='#6d4698' />
              <stop offset='30%' stopColor='#b74393' />
              <stop offset='60%' stopColor='#e84273' />
              <stop offset='100%' stopColor='#f5c85e' />
            </linearGradient>
          </defs>
          <circle
            className='path'
            stroke='url(#gradient)'
            cx='25'
            cy='25'
            r='20'
            fill='none'
            strokeWidth='2'
          ></circle>
        </svg>
      </div>
    )
  }
}

export default InitialLoader

import React from 'react'
import { css } from '@emotion/react'
import * as Hooks from '../../hooks'
import * as Common from '../../common'
import * as Modules from '.'
import * as Libs from 'libs'
export default props => {
  const langkey = Libs.Common.Func.getLangKey()

  let productEdges = Hooks.AllProductsEn.AllProductsEn()
  let shopEdge = Hooks.AllShop.AllShop()

  let edges = productEdges
  const column = 3
  const shuffle = ([...array], max) => {
    const len = max ? max : array.length - 1
    for (let i = len; i >= 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
  }
  edges = edges.slice(0, column)
  //edges = shuffle(edges).slice(0, column)
  edges = edges.map(({ node }) => {
    return node
  })
  const sectionCss = css`
    ${Libs.Common.Func.getPcSpVwValue('margin-top', 80, 90)};
  `

  return (
    <section css={sectionCss}>
      <Modules.ProductsCategory
        items={edges}
        lang={langkey}
        column={column}
        variants={shopEdge}
        type={'indexShop'}
      />
    </section>
  )
}

import { useStaticQuery, graphql } from "gatsby";

export const MagazineCategoryTagData = () => {
	const { contentfulCommonCategory } = useStaticQuery(
		graphql`
			query CommonMagazineCategoryJaQuery {
				contentfulCommonCategory(slug: { eq: "magazine" }) {
					node_locale
					slug
					content___magazine {
						tags {
							name
							slug
							group
						}
					}
				}
			}
		`
	);
	return contentfulCommonCategory;
};

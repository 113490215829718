import React, { Component } from 'react'
import { css } from '@emotion/react'
import * as Styles from '../../libs/styles'
import * as Libs from 'libs'

export default props => {
  const { children, ...rest } = props
  const symbolContCss = () => css`
    position: relative;
    .symbol-name {
      position: absolute;
      top: 0px;
      line-height: 100%;
      text-align: center;
      z-index: 10;
      ${Libs.Styles.Font.Bold()};

      ${Styles.Mq.moreLarge} {
        width: 133px;
        line-height: 52px;
      }
      ${Styles.Mq.pc} {
        width: 116px;
        line-height: 24px;
      }
      ${Styles.Mq.tb} {
        width: 90px;
        line-height: 36px;
      }
      ${Styles.Mq.lessPab} {
        width: 90px;
        line-height: 36px;
      }
    }
    &:before {
      position: absolute;
      top: 0px;
      background-color: #000;
      border-radius: 5px;
      content: '';
      display: block;
      height: 10px;

      ${Libs.Styles.Mq.moreLarge} {
        width: calc(100% - 143px);
        left: 143px;
        top: 20px;
      }
      ${Libs.Styles.Mq.pc} {
        width: calc(100% - 126px);
        left: 126px;
        top: 7px;
      }
      ${Libs.Styles.Mq.tb} {
        width: calc(100% - 94px);
        left: 100px;
        top: 13px;
      }
      ${Libs.Styles.Mq.lessPab} {
        width: calc(100% - 94px);
        left: 100px;
        top: 13px;
      }
    }
  `

  const style = () => css`
    z-index: 10000;
    fill: #000;
    height: 45px;

    ${Styles.Mq.moreLarge} {
      height: 52px;
    }
    ${Styles.Mq.pc} {
      height: 45px;
      margin-top: -11px;
    }
    ${Styles.Mq.tb} {
      height: 36px;
    }
    ${Styles.Mq.pab} {
      height: 36px;
    }
    ${Styles.Mq.sp} {
      height: 36px;
    }
  `
  return (
    <div css={symbolContCss} {...rest}>
      <svg
        className={'symbol02'}
        css={style}
        viewBox='0 0 159.52 55.18'
        preserveAspectRatio='xMinYMin meet'
      >
        <use xlinkHref='#icon-symbol02' x='0' y='0' />
      </svg>
      <p className='symbol-name'>{children}</p>
    </div>
  )
}

import { useStaticQuery, graphql } from 'gatsby'

export const AllShop = () => {
  const { allShopifyProductVariant } = useStaticQuery(
    graphql`
      query allShopQuery {
        allShopifyProductVariant {
          edges {
            node {
              id
              availableForSale

              presentmentPrices {
                price {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
      }
    `
  )
  return allShopifyProductVariant.edges
}

import React from 'react'
import { css } from '@emotion/react'
import * as Atoms from './'
export const ButtonClose = props => {
  const { className, ...rest } = props
  const {
    classStr = null,
    iconSize = '40px',
    rectSize = '3px',
    color = '#000',
    clickHandler = null,
    activeClass = null,
    ...newProps
  } = rest
  const contCss = () => css`
    position: relative;
    width: ${iconSize};
    height: ${iconSize};
    cursor: pointer;
    border: 0;
    background-color: transparent;
    .icon-close {
      width: ${iconSize};
      height: ${iconSize};
    }
    & > div {
      pointer-events: none;
    }
  `

  return (
    <button
      css={contCss}
      onClick={() => {
        if (clickHandler) clickHandler()
      }}
      className={className}
      {...newProps}
    >
      <Atoms.IconClose
        iconSize={iconSize}
        activeClass={activeClass}
        rectSize={rectSize}
        color={color}
      />
    </button>
  )
}

import React, { useEffect, useRef, useState } from 'react'

import { css } from '@emotion/react'
import { StaticImage } from 'gatsby-plugin-image'

import * as Common from '../../common'
import * as Atoms from '../atoms'
import * as Libs from 'libs'

export default props => {
  const { lang, ...rest } = props

  useEffect(() => {
    // Libs.Common.Config.excuteTransition()
  }, [])
  const sectionCss = () => {
    return css`
      ${Libs.Common.Func.getPcSpVwValue('padding-top', 172, 60, true)};
      ${Libs.Common.Func.getPcSpVwValue('padding-bottom', 172, 60, true)};
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      row-gap: 4rem;
      ${Libs.Styles.Mq.moreTab} {
        column-gap: 3rem;
      }
      ${Libs.Styles.Mq.lessPab} {
        column-gap: 1rem;
      }
      h2 {
        ${Libs.Common.Func.getPcSpVwValue('font-size', 172, 60, true)};
        ${Libs.Common.Func.getPcSpVwValue('line-height', 172, 60, true)};
        ${Libs.Styles.Font.Bold()}
        letter-spacing:-0.03em;

        ${Libs.Styles.Mq.moreTab} {
          grid-column: 4/9;
          grid-row: 1/3;
        }
        ${Libs.Styles.Mq.lessPab} {
          grid-column: 2/7;
        }
      }
      .caption {
        ${Libs.Common.Func.getPcSpVwValue('font-size', 14, 14, true)};
        ${Libs.Common.Func.getPcSpVwValue('line-height', 14, 14, true)};

        ${Libs.Styles.Mq.moreTab} {
          grid-column: 1/3;
          grid-row: 3/4;
          justify-self: end;
        }
        ${Libs.Styles.Mq.lessPab} {
          grid-column: 10/12;
          align-self: top;
          justify-self: end;
          text-align: right;
        }
      }
      .description {
        ${Libs.Common.Func.getPcSpVwValue('font-size', 18, 18, true)};
        ${Libs.Common.Func.getPcSpVwValue('line-height', 26, 26, true)};
        ${Libs.Styles.Mq.moreTab} {
          grid-column: 4/9;
          grid-row: 3/4;
          justify-self: end;
          align-self: end;
        }
        ${Libs.Styles.Mq.lessPab} {
          grid-column: 2/11;
        }
      }
      .img01 {
        ${Libs.Styles.Mq.moreTab} {
          grid-column: 9/12;
          grid-row: 2/4;
          justify-self: end;
          align-self: end;
        }
        ${Libs.Styles.Mq.lessPab} {
          grid-column: 7/12;
        }
      }
      .hc-view {
        clip-path: polygon(7% 0%, 92% 0%, 92% 100%, 7% 100%);
        ${Libs.Styles.Mq.moreTab} {
          grid-column: 1/-1;
          grid-row: 5/10;
        }
        ${Libs.Styles.Mq.lessPab} {
          grid-column: 1/-1;
        }
      }
    `
  }

  return (
    <section className='index-about-cont' css={sectionCss}>
      <h2>About</h2>
      <div className={'caption'}>
        <Libs.Atoms.TriggerLink
          class='inner-link'
          transition={Libs.Transition.TrigerPageTransition.TrigerPageTransition}
          to={`/${lang}/coffee/`}
        >
          <Atoms.IconParallelogram />
          <p>
            View
            <br />
            More About
          </p>
        </Libs.Atoms.TriggerLink>
      </div>
      <div className='description rt-cont'>
        <p className={'rt-content rt-textfield default-text'}>
          {Common.Localize.getIndexAboutText(lang)}
        </p>
      </div>
      <StaticImage
        className={'img01'}
        src='../../images/hc-sign.jpg'
        alt='Shiratama'
        quality={100}
      />

      <StaticImage
        className={'hc-view'}
        src='../../images/hc-fukuoka01.jpg'
        alt='Howlt Coffee Fukuoka'
        quality={100}
      />
    </section>
  )
}

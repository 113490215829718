import React from 'react'
import { css } from '@emotion/react'
export default props => {
  const style = () => css`
    width: 34px;
    height: 13px;
    overflow: hidden;
    line-height: 0px;
    display: flex;

    .icon-parallelogra-item {
      height: 13px;
    }
    svg {
      margin-top: 0%;
      fill: #000;
      height: 100%;
    }
  `

  return (
    <div css={style} className='icon-parallelogra-cont'>
      <div className='icon-parallelogra-item'>
        <svg viewBox='0 0 17.51 4' preserveAspectRatio='xMinYMin meet'>
          <use xlinkHref='#icon-parallelogram' x='0' y='0' />
        </svg>
      </div>
      <div className='icon-parallelogra-item'>
        <svg viewBox='0 0 17.51 4' preserveAspectRatio='xMinYMin meet'>
          <use xlinkHref='#icon-parallelogram' x='0' y='0' />
        </svg>
      </div>
    </div>
  )
}

import { gsap } from 'gsap'

import * as Common from '../../common'
import SplitType from 'split-type'
import * as Libs from 'libs'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
let headerTimeLine = null
let indexTimeLine = null
let closeButtonTimeLine = null
export const gnavChersMap = new Map()
export let footerTimeline = null

//--------------------------------------
//  ScrollTrigger Start
//--------------------------------------
export const initScrollTrigger = () => {
  Common.Config.initSmoothScrollBar()
  headerScrollTrigger()
  footerScrollTrigger()
  //closeButtonScrollTrigger()
}
//--------------------------------------
//  For Header
//--------------------------------------

export const headerScrollTrigger = () => {
  if (headerTimeLine) {
    headerTimeLine.kill()
    headerTimeLine = null
  }

  let initPosition
  if (
    Libs.Common.Func.getDeviceSize() !== 'lessPab' &&
    !Libs.Common.Config.isMobile()
  ) {
    initPosition = '200px'
  } else if (Libs.Common.Func.getDeviceSize() === 'lessPab') {
    initPosition = '150px'
  }

  headerTimeLine = gsap.timeline({
    scrollTrigger: {
      trigger: '.scroll-content',
      start: `top+=${initPosition} 0`,
      end: 'bottom+=100000 top',
      scroller: window.scroller ? window.scroller : window,
      toggleClass: {
        targets: 'html',
        className: 'on-scrollinit'
      }
    }
  })
}

//--------------------------------------
//  For CloseButton
//--------------------------------------

export const closeButtonScrollTrigger = () => {
  if (closeButtonTimeLine) {
    closeButtonTimeLine.kill()
    closeButtonTimeLine = null
  }

  closeButtonTimeLine = gsap.timeline({
    scrollTrigger: {
      trigger: 'body',
      endTrigger: '.footer',
      scroller: window.scroller ? window.scroller : window,

      start: 'top top+100',
      end: 'bottom-500 bottm',
      pin: '.button-close',
      invalidateOnRefresh: true,
      scrub: 1
    }
  })
}
//--------------------------------------
//  Footer Animation
//--------------------------------------

export const footerScrollTrigger = () => {
  if (footerTimeline) {
    footerTimeline.kill()
    footerTimeline = null
  }

  footerTimeline = gsap
    .timeline({
      onComplete: function () {
        gsap.to('.icon-parallelogra-item', {
          xPercent: -100,
          duration: 2,
          repeat: -1,
          ease: 'power4.inOut'
        })

        gsap
          .timeline({
            scrollTrigger: {
              trigger: '.footer',
              scroller: window.scroller ? window.scroller : window,
              pinType: 'transform',
              start: 'top bottom',
              end: 'bottom bottom',
              invalidateOnRefresh: true,
              scrub: true
            },
            force3D: true,

            ease: 'power2.inOut'
          })
          .set('.footer-inner-cont', {
            y: -150
          })
          .set('body', {
            backgroundColor: '#fff'
          })
          .to('.project-container', {
            scale: 0.95,
            borderRadius: 14,
            transformOrigin: 'center bottom'
          })
          .to(
            'body',
            {
              backgroundColor: '#ebc7c4'
            },
            '<'
          )
          .to(
            '.footer-inner-cont',
            {
              y: 0
            },
            '<'
          )
      }
    })
    .set('.project-container', {
      scale: 1
    })
    .set('body', {
      backgroundColor: '#fff'
    })
    .to({}, 0.25, {}, '<')
}

//--------------------------------------
//  Index Transition Sp
//--------------------------------------

export const IndexSpTransition = () => {
  if (indexTimeLine) {
    indexTimeLine.kill()
    indexTimeLine = null
  }
  let split = SplitType.create(
    '.index-right-section .text-cont .title-cont h1',
    {
      type: 'chars'
    }
  )

  const tl = gsap.timeline()
  indexTimeLine = tl
  tl.to({}, 0.25, {}, '<').call(
    function () {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: '.index-left-section',
            endTrigger: '.index-right-section',
            scroller: window.scroller ? window.scroller : window,
            start: 'top+100 top',
            end: 'top center',
            /*pin: '.key-visual-cont',*/
            invalidateOnRefresh: true,
            scrub: true,
            anticipatePin: 1,
            immediateRender: false
          },
          force3D: true
        })
        .to('.index-left-section .img-cont', {
          scale: 1.134,
          opacity: 0.25,
          transformOrigin: 'center bottom'
        })
        .to(
          '.index-left-section .gatsby-image-wrapper img',
          {
            borderRadius: 0
          },
          '<'
        )
      gsap
        .timeline({
          scrollTrigger: {
            trigger: '.index-right-section',
            scroller: window.scroller ? window.scroller : window,
            start: 'top+100 top',
            end: 'bottom top',
            /*pin: '.key-visual-cont',*/
            invalidateOnRefresh: true,
            scrub: true,
            anticipatePin: 1,
            immediateRender: false
          },
          force3D: true
        })
        .to('.index-right-section .gatsby-image-wrapper', {
          scale: 1.2,
          opacity: 0.3,
          y: '-50'
        })

      gsap
        .timeline({
          scrollTrigger: {
            trigger: '.index-right-section .text-cont',
            scroller: window.scroller ? window.scroller : window,
            start: 'top-200 center',
            end: 'bottom center',
            invalidateOnRefresh: true,
            scrub: true,
            anticipatePin: 1,
            immediateRender: false
          },
          force3D: true
        })

        .to(
          split.chars,
          0.5,
          {
            y: '0%',
            ease: 'power4.inOut',
            stagger: 0.01
          },
          '-=.1'
        )

      IdexCoffeeTrigger('bottom')
    },
    {},
    '-=.8'
  )
}
//--------------------------------------
//  Index Animation Sp
//--------------------------------------

export const IndexSpScrollTrigger = () => {
  if (indexTimeLine) {
    indexTimeLine.kill()
    indexTimeLine = null
  }

  let split = SplitType.create(
    '.index-right-section .text-cont .title-cont h1',
    {
      type: 'chars'
    }
  )

  const tl = gsap.timeline()
  indexTimeLine = tl

  const splitWords = gsap.utils.toArray('.index-left-section .split-type.words')

  tl.set('.index-left-section', {
    scaleY: 0
  })
    .set('.index-left-section .img-cont,.clip-bottom', {
      'clip-path': 'polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)'
    })

    .set('.hc-logo svg', {
      y: '100%'
    })
    .set(split.chars, {
      y: '100%'
    })
  const words = []
  splitWords.forEach(function (sw, i) {
    const word = SplitType.create(sw, { types: 'line, words' })
    words.push(word.words)
    tl.set(word.words, {
      y: '100%'
    })
  })
  tl.to({}, 0.25, {}, '<')
    .set('.index-left-section .gatsby-image-wrapper', {
      rotate: 1,
      scale: 1.01,
      y: 40,
      z: 50
    })
    .to('.index-left-section', {
      scaleY: 1,
      force3D: true,
      duration: 1.2,
      ease: 'power4.inOut',
      transformOrigin: 'center bottom'
    })

    .set(
      '.index-left-section .img-cont',
      {
        'clip-path': 'polygon(0% 60%, 100% 80%, 100% 100%, 0% 100%)'
      },
      '-=.1'
    )
    .to(
      '.index-left-section .img-cont',
      {
        'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',

        duration: 0.5,
        ease: 'power4.easeOut'
      },
      '<'
    )
    .to(
      '.index-left-section .gatsby-image-wrapper',
      {
        y: 0,
        z: 0,
        scale: 1,
        rotate: 0,
        duration: 0.5,
        ease: 'power4.easeOut',
        transformOrigin: 'top bottom'
      },
      '<'
    )
    .call(
      function () {
        const cont = document.querySelector('html')
        cont.classList.remove('init-animation')
        window.scrollTo(0, 0)

        gsap
          .timeline({
            scrollTrigger: {
              trigger: '.index-left-section',
              endTrigger: '.index-right-section',
              scroller: window.scroller ? window.scroller : window,
              start: 'top+100 top',
              end: 'top center',
              /*pin: '.key-visual-cont',*/
              invalidateOnRefresh: true,
              scrub: true,
              anticipatePin: 1,
              immediateRender: false
            },
            force3D: true
          })
          .to('.index-left-section .img-cont', {
            scale: 1.134,
            opacity: 0.25,
            transformOrigin: 'center bottom'
          })
          .to(
            '.index-left-section .gatsby-image-wrapper img',
            {
              borderRadius: 0
            },
            '<'
          )
        gsap
          .timeline({
            scrollTrigger: {
              trigger: '.index-right-section',
              scroller: window.scroller ? window.scroller : window,
              start: 'top+100 top',
              end: 'bottom top',
              /*pin: '.key-visual-cont',*/
              invalidateOnRefresh: true,
              scrub: true,
              anticipatePin: 1,
              immediateRender: false
            },
            force3D: true
          })
          .to('.index-right-section .gatsby-image-wrapper', {
            scale: 1.2,
            opacity: 0.3,
            y: '-50'
          })

        gsap
          .timeline({
            scrollTrigger: {
              trigger: '.index-right-section .text-cont',
              scroller: window.scroller ? window.scroller : window,
              start: 'top-200 center',
              end: 'bottom center',
              invalidateOnRefresh: true,
              scrub: true,
              anticipatePin: 1,
              immediateRender: false
            },
            force3D: true
          })

          .to(
            split.chars,
            0.5,
            {
              y: '0%',
              ease: 'power4.inOut',
              stagger: 0.01
            },
            '-=.1'
          )

        IdexCoffeeTrigger('bottom')
      },
      {},
      '-=.8'
    )
    .to(
      '.hc-logo svg',
      1,
      {
        y: '0%',
        ease: 'power4.inOut'
      },
      '<'
    )
    .to({}, 0.1, {}, '-=.8')

  words.forEach(function (word, i) {
    tl.to(
      word,
      1,
      {
        y: '0%',
        ease: 'power4.inOut',
        stagger: 0
      },
      '<'
    )
  })
  tl.set(
    '.clip-bottom',
    {
      'clip-path': 'polygon(0% 60%, 100% 80%, 100% 100%, 0% 100%)'
    },
    '<'
  ).to(
    '.clip-bottom',
    {
      'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',

      duration: 0.5,
      ease: 'power4.easeOut'
    },
    '<'
  )
}

//--------------------------------------
//  Index Animation
//--------------------------------------
export const IndexTransition = () => {
  if (indexTimeLine) {
    indexTimeLine.kill()
    indexTimeLine = null
  }
  const tl = gsap.timeline()
  indexTimeLine = tl
  tl.to({}, 0.25, {}, '<').call(
    function () {
      gsap.to('.index-right-section .gatsby-image-wrapper', {
        y: '+=400',
        opacity: 0.5,
        scrollTrigger: {
          trigger: '.index-right-section',
          scroller: window.scroller ? window.scroller : window,
          pinType: 'transform',
          start: 'top+100 top',
          end: 'bottom top',
          /*pin: '.key-visual-cont',*/
          invalidateOnRefresh: true,
          scrub: true
        },
        force3D: false
      })
      gsap.to('.index-left-section .img-cont', {
        y: '+=400',
        opacity: 0.5,
        scrollTrigger: {
          trigger: '.index-left-section',
          scroller: window.scroller ? window.scroller : window,
          pinType: 'transform',
          start: 'top+100 top',
          end: 'bottom top',
          /*pin: '.key-visual-cont',*/
          invalidateOnRefresh: true,
          scrub: true
        },
        force3D: false
      })

      IdexCoffeeTrigger('center')
    },
    {},
    '-=.8'
  )
}
export const IndexScrollTrigger = () => {
  if (indexTimeLine) {
    indexTimeLine.kill()
    indexTimeLine = null
  }

  const tl = gsap.timeline()
  indexTimeLine = tl

  let split = SplitType.create(
    '.index-right-section .text-cont .title-cont h1',
    {
      type: 'chars'
    }
  )
  const splitWords = gsap.utils.toArray('.split-type.words')

  tl.set('.index-left-section, .index-right-section', {
    scaleY: 0
  })
    .set(
      '.index-left-section .img-cont,.index-right-section .img-cont,.clip-bottom',
      {
        'clip-path': 'polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)'
      }
    )
    .set(split.chars, {
      y: '100%'
    })
    .set('.hc-logo svg', {
      y: '100%'
    })
  const words = []
  splitWords.forEach(function (sw, i) {
    const word = SplitType.create(sw, { types: 'line, words' })
    console.log('word --- ', word)
    words.push(word.words)

    tl.set(word.words, {
      y: '100%'
    })
  })
  tl.to({}, 0.25, {}, '<')
    .set(
      '.index-left-section .gatsby-image-wrapper,.index-right-section .gatsby-image-wrapper',
      {
        rotate: 1,
        scale: 1.01,
        y: 40,
        z: 50
      }
    )
    .to('.index-left-section', {
      scaleY: 1,
      force3D: true,
      duration: 1.2,
      ease: 'power4.inOut',
      transformOrigin: 'center bottom'
    })
    .to(
      '.index-right-section',
      {
        scaleY: 1,
        force3D: true,
        duration: 1.2,
        ease: 'power4.inOut',
        transformOrigin: 'center top'
      },
      '<'
    )
    .set(
      '.index-left-section .img-cont,.index-right-section .img-cont',
      {
        'clip-path': 'polygon(0% 60%, 100% 80%, 100% 100%, 0% 100%)'
      },
      '-=.1'
    )
    .to(
      '.index-left-section .img-cont,.index-right-section .img-cont',
      {
        'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',

        duration: 0.5,
        ease: 'power4.easeOut'
      },
      '<'
    )
    .to(
      '.index-left-section .gatsby-image-wrapper,.index-right-section .gatsby-image-wrapper',
      {
        y: 0,
        z: 0,
        scale: 1,
        rotate: 0,
        duration: 0.5,
        ease: 'power4.easeOut',
        transformOrigin: 'top bottom'
      },
      '<'
    )
    .call(
      function () {
        if (!Libs.Common.Config.isTouchDevice()) {
          initScrollTrigger()
        }
        const cont = document.querySelector('html')
        cont.classList.remove('init-animation')

        gsap.to('.index-right-section .gatsby-image-wrapper', {
          y: '+=400',
          opacity: 0.5,
          scrollTrigger: {
            trigger: '.index-right-section',
            scroller: window.scroller ? window.scroller : window,
            pinType: 'transform',
            start: 'top+100 top',
            end: 'bottom top',
            /*pin: '.key-visual-cont',*/
            invalidateOnRefresh: true,
            scrub: true
          },
          force3D: false
        })
        gsap.to('.index-left-section .img-cont', {
          y: '+=400',
          opacity: 0.5,
          scrollTrigger: {
            trigger: '.index-left-section',
            scroller: window.scroller ? window.scroller : window,
            pinType: 'transform',
            start: 'top+100 top',
            end: 'bottom top',
            /*pin: '.key-visual-cont',*/
            invalidateOnRefresh: true,
            scrub: true
          },
          force3D: false
        })

        IdexCoffeeTrigger('center')
      },
      {},
      '<'
    )
    .to(
      split.chars,
      1,
      {
        y: '0%',
        ease: 'power4.inOut',
        stagger: 0.04
      },
      '-=.1'
    )
    .to(
      '.hc-logo svg',
      1,
      {
        y: '0%',
        ease: 'power4.inOut'
      },
      '<'
    )

    .to({}, 0.1, {}, '-=.8')

  words.forEach(function (word, i) {
    tl.to(
      word,
      1,
      {
        y: '0%',
        ease: 'power4.inOut',
        stagger: 0
      },
      '<'
    )
  })

  tl.set(
    '.clip-bottom',
    {
      'clip-path': 'polygon(0% 60%, 100% 80%, 100% 100%, 0% 100%)'
    },
    '<'
  ).to(
    '.clip-bottom',
    {
      'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',

      duration: 0.5,
      ease: 'power4.easeOut'
    },
    '<'
  )
}

//--------------------------------------
//  Index Coffee
//--------------------------------------

export const IdexCoffeeTrigger = position => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: '.hc-view',
        scroller: window.scroller ? window.scroller : window,
        pinType: 'transform',
        start: `top ${position}`,
        end: 'top top',
        /*pin: '.key-visual-cont',*/
        invalidateOnRefresh: true,
        scrub: true
      },
      force3D: false
    })

    .set('.hc-view img', {
      scale: 1.01,
      y: '-=50'
    })
    .to(
      '.hc-view',
      {
        'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
      },
      '<'
    )
    .to(
      '.hc-view img',
      {
        scale: 1,
        y: 0
      },
      '<'
    )
}

import { css } from '@emotion/react'
import * as Libs from 'libs'

import HowltItalicWoff from '../font/FeijoaWeb-MediumItalic.woff2'
import HowltItalicEot from '../font/FeijoaWeb-MediumItalic.eot'
import HowltSansWoff from '../font/howlt-sans-reg.woff2'
import HowltSansTtf from '../font/howlt-sans-reg.eot'
import HowltSansBoldWoff from '../font/howltsans-b.woff2'
import HowltSansBoldEot from '../font/howltsans-b.eot'
import HowltJPOtf from '../font/howltjp.woff2'
import DomaineEot from '../font/DomaineTextWeb-Regular.eot'
import DomaineWoff from '../font/DomaineTextWeb-Regular.woff'

export const FontFace = props => {
  Libs.Styles.Font.setSanSerifFont('Howlt')
  Libs.Styles.Font.setBoldFont("'hcBold', 'Noto Sans JP'")

  return css`
    @font-face {
      font-family: 'HowltItalic';
      src: url(${HowltItalicWoff}) format('woff2'),
        url(${HowltItalicEot}) format('embedded-opentype');
      font-weight: 400;
      font-style: italic;
      font-stretch: normal;
      unicode-range: U + 000d-FB04;
    }

    @font-face {
      font-family: 'hcBold';
      src: url(${HowltSansBoldWoff}) format('woff2'),
        url(${HowltSansBoldEot}) format('embedded-opentype');
      font-weight: 400;

      font-style: normal;
      font-stretch: normal;
      unicode-range: U + 000d-FB04;
    }
    @font-face {
      font-family: 'Howlt';
      src: url(${HowltSansWoff}) format('woff2'),
        url(${HowltSansTtf}) format('embedded-opentype');
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      unicode-range: U + 000d-FB04;
    }
    @font-face {
      font-family: 'Domaine';
      src: url(${DomaineWoff}) format('woff2'),
        url(${DomaineEot}) format('embedded-opentype');
      font-style: normal;
      font-weight: 500;
    }
    @font-face {
      font-family: 'HowltJP';
      src: url(${HowltJPOtf}) format('woff2');
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      unicode-range: U + 000d-FB04;
    }
  `
}

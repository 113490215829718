import React, { useEffect, useContext, useState } from 'react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import HeaderLogo from '../../components/modules/HeaderLogo'
import { css } from '@emotion/react'
import SnsList from '../../components/modules/SnsList'
import { useDispatch } from 'react-redux'
import StoreContext from 'libs/context/StoreContext'
import * as Modules from '../modules'
import * as Atoms from '../atoms'
import * as Action from 'libs/redux/action'
import * as Libs from 'libs'
import * as Transition from '../Transition'

const headerCss = () =>
  css`
    position: fixed;
    z-index: 100;

    transition: all 1s cubic-bezier(0.71, 0.01, 0.45, 1.01);
    width: 100vw;
    box-sizing: border-box;
    height: 119px;
    transform: translateY(0px);
    ${Libs.Styles.Mq.lessPab} {
      height: 95px;
    }
    .init-animation & {
      transform: translateY(-300px);
    }
    .init-loaded:not(.on-scrollinit):not(.on-scrolldown):not(.on-scrollup) & {
    }

    .on-scrollinit & {
      ${Libs.Styles.Mq.moreTab} {
        transform: translateY(-85px);
      }
      ${Libs.Styles.Mq.lessPab} {
        /*top: -59px;*/
        transform: translateY(-59px);
      }
    }

    .category-shop.type-post &,
    .category-magazine.type-post & {
      ${Libs.Styles.Mq.moreTab} {
        transform: translateY(-85px);
      }

      ${Libs.Styles.Mq.lessPab} {
        transform: translateY(-105px);
      }
    }
    .now-transition & {
      /*top: -200px;*/
    }
  `
const h1 = () => css`
  position: absolute;
  width: 83vw;

  transition: top 1s cubic-bezier(0.71, 0.01, 0.45, 1.01);
  ${Libs.Styles.Mq.moreLarge} {
    left: 50px;
    top: 65px;
  }
  ${Libs.Styles.Mq.pc} {
    left: 30px;
    top: 64px;
  }
  ${Libs.Styles.Mq.tb} {
    left: 30px;
    top: 64px;
  }
  ${Libs.Styles.Mq.lessPab} {
    top: 26px;
    left: 25px;
  }
  .on-scrollinit:not(.on-story) &,
  .category-shop.type-post &,
  .category-magazine.type-post & {
    top: 35px;
  }
  &:before {
    position: absolute;
    top: 7px;
    background-color: #000;
    border-radius: 5px;
    content: '';
    display: block;
    height: 10px;
    ${Libs.Styles.Mq.moreLarge} {
      width: calc(100vw - 143px - 100px);
      left: 143px;
    }
    ${Libs.Styles.Mq.pc} {
      width: calc(100vw - 143px - 60px);
      left: 126px;
    }
    ${Libs.Styles.Mq.tb} {
      width: calc(100vw - 143px - 40px);
      left: 126px;
    }
    ${Libs.Styles.Mq.lessPab} {
      width: calc(100vw - 143px - 75px);
      left: 100px;
    }
  }
`
const snslistCss = () => css`
  position: absolute;
  right: 130px;
  top: 35px;
  ${Libs.Styles.Mq.tb} {
    right: 110px;
  }
  ${Libs.Styles.Mq.lessPab} {
    display: none;
  }
`

const bacCss = () => css`
  position: absolute;
  right: 50px;
  top: 35px;
  ${Libs.Styles.Mq.tb} {
    right: 30px;
  }
  ${Libs.Styles.Mq.lessPab} {
    right: 20px;
    top: 22px;
  }
`
const langCss = () => css`
  position: absolute;
  right: 85px;

  width: 40px;
  ${Libs.Styles.Mq.moreLarge} {
    top: 36px;
  }
  ${Libs.Styles.Mq.pc} {
    top: 36px;
  }
  ${Libs.Styles.Mq.tb} {
    right: 65px;
    top: 36px;
  }
  ${Libs.Styles.Mq.lessPab} {
    right: 50px;
    top: 25px;
  }
`
const Header = props => {
  useEffect(() => {
    Transition.ScrollTriggerTransition.headerScrollTrigger()
  }, [])

  //--------------------------------------
  //  Scripts
  //--------------------------------------
  const { restoreCheckout } = useContext(StoreContext)
  const langKey = Libs.Common.Func.getLangKey()
  const [lang, setLang] = useState(langKey)
  const dispatch = useDispatch()

  const clickHandler = (event, id) => {
    const key = event.currentTarget.dataset.key
    let langInfo = Libs.Common.Func.getLangInfo()
    const langKey = Libs.Common.Func.getLangKey()

    if (langKey === key) return

    Libs.Common.Config.setCurrentLang(key)

    setLang(key)

    restoreCheckout()

    dispatch(Action.LangChanged(key))
  }
  const langInfo = Libs.Common.Func.getLangInfo()

  return (
    <header css={headerCss}>
      <h1 css={h1}>
        <HeaderLogo />
      </h1>
      <Modules.GlobalNavigation />
      {/*<div css={menuiconCss}>
          <HamburgerMenu />
        </div>
				<Modules.Breadcrumb></Modules.Breadcrumb>
				<Modules.TagFilter />*/}
      <Libs.Atoms.IconBag css={bacCss} />
      <Atoms.Dropdown
        css={langCss}
        active={lang}
        items={[
          {
            text: 'JA',
            slug: 'ja',
            url: langInfo[0].link,
            onClick: clickHandler
          },
          {
            text: 'EN',
            slug: 'en',
            url: langInfo[1] ? langInfo[1].link : '/',
            onClick: clickHandler
          }
        ]}
        trigerLink={Libs.Transition.TrigerPageTransition.TrigerPageTransition}
      />
      <div css={snslistCss}>
        <SnsList styles={{ width_pc: 6, width_sp: 6 }} />
      </div>
    </header>
  )
}
export default Header

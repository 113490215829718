import { navigate } from 'gatsby'

import { Power3 } from 'gsap'
import * as Common from '../common'
import * as Action from '../redux/action'
import { TimelineMax } from 'gsap'
import * as Transition from './'
import * as Config from '../common/config'

let href
let cloneNode
let dispatcher

export const TrigerPageTransition = (to, dispatch) => {
  href = to

  dispatcher = dispatch
  const cont = document.querySelector('html')
  cont.classList.remove('on-story', 'on-overlay')
  cont.classList.add('now-transition', 'transition-fade-in', `page-load`)
  let tlwrapper = document.querySelector('.tl-wrapper')
  const tl = new TimelineMax()
  const scrollbar = window.Scrollbar

  const topvalue = scrollbar
    ? scrollbar.scrollTop
    : window.scrollY + window.innerHeight / 2
  tl.add('page')
  tl.to(
    tlwrapper,
    0.5,
    {
      opacity: 0,
      scale: 0.96,
      ease: Power3.easeInOut,
      transformOrigin: `center ${topvalue}px`
    },
    'page'
  ).call(function () {
    cont.classList.add('transition-fade-in-entry')
    const entryCont = document.querySelector('.tl-edges')

    entryCont.style.opacity = 0
    const classList = Config.classList

    cont.classList.remove('category-index')

    Common.Config.setTransition(
      Transition.TrigerPageTransition.StartPageTransition
    )

    navigate(href)
  })
}
export const StartPageTransition = () => {
  entryTransition()
}

const entryTransition = node => {
  const tl = new TimelineMax({
    onComplete: function () {
      const cont = document.querySelector('html')
      cont.classList.remove(
        'transition-fade-in',
        `transition-fade-in-entry`,
        `page-load`
      )
    }
  })
  let entryCont = null

  const cont = document.querySelector('html')
  cont.classList.remove('now-transition')

  let tlEdge = document.querySelector('.tl-edges')

  tl.set(tlEdge, { opacity: 0, y: 60 })

    .call(function () {
      entryCont = document.querySelector(
        '.tl-wrapper--mount .project-container'
      )

      Common.Func.setType(entryCont)
      Common.Func.excuteTransitionFunc()
      if (typeof window !== `undefined`) {
        const scrollbar = window.Scrollbar
        window.scrollTo(0, 0)

        if (scrollbar) {
          scrollbar.scrollTo(0, 0, 0, {
            callback: () => console.log('done!')
          })
        } else {
        }
      }

      const dataset = entryCont.dataset
      let current = dataset.currentname
        ? { name: dataset.currentname, slug: dataset.current }
        : null
      dispatcher(
        Action.TransitionComplete(
          current,
          dataset.prev,
          dataset.next,
          dataset.categoryname
            ? { name: dataset.categoryname, slug: dataset.categoryslug }
            : null,
          dataset.type
        )
      )
    })

    .to(tlEdge, 0.5, { opacity: 1, y: 0, ease: Power3.easeInOut })
}

import React, { useEffect, useRef, useState } from 'react'

import { css } from '@emotion/react'

import * as Atoms from '../../components/atoms'

import * as Modules from '../../components/modules'
import * as Common from '../../common'
import * as Transition from '../Transition'
import * as Libs from 'libs'

export default props => {
  //--------------------------------------
  //  Libs.Styless
  //--------------------------------------

  //--------------------------------------
  //  Scripts
  //--------------------------------------

  const container = React.createRef()
  const langKey = Libs.Common.Func.getLangKey()

  useEffect(() => {
    Libs.Common.Func.setTransitionFucn(
      Transition.ScrollTriggerTransition.footerScrollTrigger
    )
    return () => {}
  })

  const containerCss = () => {
    return css`
      ${Libs.Common.Func.getPcSpVwValue('margin-top', 0, 14, true)};

      height: auto;
      width: 100vw;
      position: relative;
      overflow: hidden;
      z-index: 20;
      margin: 0 auto;

      ${Libs.Styles.Mq.moreTab} {
        padding-left: 3vw;
        padding-right: 3vw;
      }
      ${Libs.Styles.Mq.lessPab} {
        padding-left: 5vw;
        padding-right: 5vw;
      }
      .footer-inner-cont {
        display: grid;

        ${Libs.Styles.Mq.moreTab} {
          grid-template-columns: 1fr 1fr 1fr;
          row-gap: 10rem;
        }
        ${Libs.Styles.Mq.lessPab} {
          grid-template-columns: 1fr 1fr;
          row-gap: 3rem;
        }
      }
      ${Libs.Common.Func.getPcSpVwValue('padding-top', 100, 90, true)};
      ${Libs.Common.Func.getPcSpVwValue('padding-bottom', 40, 40, true)};
      .footer-logo {
        grid-column: 1/-1;

        svg {
          width: 100% !important;
        }
        ${Libs.Styles.Mq.moreTab} {
          grid-row: 3/4;
        }
        ${Libs.Styles.Mq.lessPab} {
          grid-row: 1/2;
        }
      }
      .info-about {
        ${Libs.Styles.Mq.moreTab} {
          justify-self: center;
        }
        ${Libs.Styles.Mq.lessPab} {
          grid-column: 2/3;
        }
      }
      .info-hours {
        ${Libs.Styles.Mq.moreTab} {
          justify-self: right;
        }
        ${Libs.Styles.Mq.lessPab} {
          grid-column: 1/-1;
        }
      }
      .copy-right {
        justify-self: right;
        ${Libs.Styles.Mq.moreTab} {
          grid-column: 3/4;
        }
        ${Libs.Styles.Mq.lessPab} {
          grid-column: 2/3;
        }
      }
    `
  }

  const navItemRefs = []
  const navItems = Common.Config.getNavItems()

  const h3Styl = {
    fontPc: {
      fontSize: 14,
      lineHeight: 14
    },
    fontSp: {
      fontSize: 14,
      lineHeight: 14
    },
    positionPc: { space: '0 0 7 0' },
    positionSp: { space: '0 0 15 0' },
    whiteSpace: 'nowrwap'
  }
  const pStyles = {
    fontPc: {
      fontFace: 'sansserif',
      fontSize: 25,
      lineHeight: 30,
      letterSpacing: -0.4
    },
    fontSp: {
      fontFace: 'sansserif',
      fontSize: 16,
      lineHeight: 22,
      letterSpacing: -0.4
    }
  }

  return (
    <nav css={containerCss} ref={container} className='footer'>
      <iv className='footer-inner-cont'>
        <h2 className={'footer-logo'}>
          <Atoms.Logo02 />
        </h2>

        <div className='footer-nav'>
          <Libs.Atoms.H3 styles={h3Styl}>Site Map</Libs.Atoms.H3>
          <ul>
            {navItems.map((item, i) => (
              <li key={`navItem${i}`}>
                <Libs.Atoms.Span data-hover={item.label} styles={pStyles}>
                  <Libs.Atoms.TriggerLink
                    transition={
                      Libs.Transition.TrigerPageTransition.TrigerPageTransition
                    }
                    to={
                      item.to !== '/visit'
                        ? `/${langKey}${item.to}`
                        : `${item.to}`
                    }
                  >
                    {item.label}
                  </Libs.Atoms.TriggerLink>
                </Libs.Atoms.Span>
              </li>
            ))}
            <li
              css={css`
                margin-top: 14px;
              `}
            >
              <Libs.Atoms.Span data-hover={'shopping-info'} styles={pStyles}>
                <Libs.Atoms.TriggerLink
                  transition={
                    Libs.Transition.TrigerPageTransition.TrigerPageTransition
                  }
                  to={`/${langKey}/shopping-info`}
                >
                  Shopping Info
                </Libs.Atoms.TriggerLink>
              </Libs.Atoms.Span>
            </li>
            <li>
              <Libs.Atoms.Span data-hover={'privacy-policy'} styles={pStyles}>
                <Libs.Atoms.TriggerLink
                  transition={
                    Libs.Transition.TrigerPageTransition.TrigerPageTransition
                  }
                  to={`/${langKey}/privacy-policy`}
                >
                  Privacy Policy
                </Libs.Atoms.TriggerLink>
              </Libs.Atoms.Span>
            </li>
          </ul>
        </div>

        <Atoms.InfoAbout></Atoms.InfoAbout>
        <Atoms.InfoHour></Atoms.InfoHour>

        <div className='credit'>
          <a href='https://loworks.co.jp' target='_new'>
            <Libs.Atoms.Span
              styles={{
                fontPc: {
                  fontSize: 14,
                  fontFace: 'sansserif',
                  lineHeight: 14,
                  letterSpacing: 0.5
                },

                fontSp: {
                  fontSize: 14,
                  fontFace: 'sansserif',
                  lineHeight: 14,
                  letterSpacing: 0.5
                }
              }}
            >
              Site by LOWORKS Inc.
            </Libs.Atoms.Span>
          </a>
        </div>

        <div className='copy-right'>
          <Libs.Atoms.Span
            styles={{
              fontPc: {
                fontSize: 14,
                letterSpacing: 0.5,
                fontFace: 'sansserif'
              },
              fontSp: {
                fontSize: 14,
                letterSpacing: 0.5,
                fontFace: 'sansserif'
              }
            }}
          >
            ©2021 Howlt . BK . NY
          </Libs.Atoms.Span>
        </div>
      </iv>
    </nav>
  )
}

import React from 'react'
import { Global, css } from '@emotion/react'
import * as LibFont from 'libs/styles/fontface'
import * as Font from './fontface'
import * as Libs from 'libs'
import * as Common from '../common'

export const StoryStyle = props => {
  const gradientStanp = props => {
    return css`
      position: relative;
      display: block !important;
      svg {
        margin-top: 0px;
      }
      a {
        position: relative;
        white-space: nowrap !important;
        display: inline-flex !important;
        align-items: center;
        padding: 5px 13px 7px 8px;
        span {
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          position: relative;
          z-index: 100;
          line-height: normal !important;
        }
        &:before {
          content: ' ';
          position: absolute;
          background-color: #fff;
          border-radius: 10px;
          width: 100%;
          height: 100%;
          left: 0px;
          z-index: 0;
        }
      }
    `
  }

  return (
    <Global
      styles={css`
        body {
          ${Libs.Styles.Mq.moreTab} {
             {
              /* overflow: hidden;*/
            }
          }
          ${Libs.Styles.Mq.lessPab} {
            .init-animation & {
              overflow: hidden;
              height: 100vh;
            }
          }
        }
        ${Font.FontFace()}
        .scroll-content {
          height: 100vh;
        }
        .project-container {
          background: #fff;
        }
        .scrollbar-track-y {
          display: none !important ;
        }
        .inner-link {
          display: flex;
          gap: 5px;
          align-items: center;
          ${Libs.Styles.Font.Bold()}
        }
        .vertical .clone-image {
          img {
            ${Libs.Styles.Mq.moreTab} {
              object-fit: contain !important;
            }
          }
        }
        .index-feature-block {
          position: relative;
          ${Libs.Styles.Mq.moreTab} {
            height: 100vh;
            > * {
              &:nth-child(2) {
                margin-bottom: max(3vh, 30px);
              }
            }
            &:first-child {
              background-color: #efbcc0;
              > div {
                &:first-child {
                  .img-cont {
                    width: min(36vw, 45vh);
                  }
                }
                &:nth-child(2) {
                  .img-cont {
                    width: min(30vh, 300px);
                  }
                }
              }
            }
            &:nth-child(2) {
              background-color: #f4b2bb;
            }
          }
          ${Libs.Styles.Mq.lessPab} {
            height: auto;
            &:first-child {
              margin-top: 160px !important;
              .rt-cont {
                display: none;
              }
            }
            &:last-child {
            }
          }
          .rt-cont {
            > .rt-content:last-child {
              text-align: center;
            }
          }
        }
        .border-button {
          button {
            border-radius: 40px;

            background-color: transparent;
            color: #000;
            border: #000 2px solid;
            line-height: 18px;
            span {
              font-size: 16px;
            }
          }
        }
        .index-cafe-block {
          .for-small-article {
          }
        }
        .link-icon-element {
          svg {
            vertical-align: middle;
            margin-right: 5px;
            margin-top: -3px;
          }
        }
        .round-background-white {
          background-color: #fff;
          color: #000;
          border-radius: 10px;
          padding: 10px;
          white-space: nowrap;
          display: inline-block !important;
        }
        .round-background-gray {
          background-color: #cccccc;
          color: #fff;
          border-radius: 10px;
          padding: 10px;
          white-space: nowrap;
          display: inline-block !important;
        }
        .red-yelow-whitebg-stamp {
          ${gradientStanp()}
          svg {
            fill: #e21000;
          }
          span {
            background-image: linear-gradient(to left, #f5c31c, orange, red);
          }
        }
        .violet-blue-whitebg-stamp {
          ${gradientStanp()}
          svg {
            fill: violet;
          }
          span {
            background-image: linear-gradient(to left, blue, indigo, violet);
          }
        }
        .rainbow-whitebg-stamp {
          ${gradientStanp()}
          span {
            background-image: linear-gradient(
              to left,
              violet,
              indigo,
              blue,
              green,
              yellow,
              orange,
              red
            );
          }
        }
        .block-bg-white {
          background-color: #fff;
          padding-top: 8px;
          padding-bottom: 10px;
          padding-left: 8px !important;
          padding-right: 8px !important;
        }
        .inline-bg-white {
          padding-left: 10px !important;
          padding-right: 10px !important;

          p {
            display: inline;
            background-color: #fff;
            box-shadow: 10px 0 0px 0px #fff, -10px 0 0px 0px #fff;
            padding-top: 1px;
            padding-bottom: 1px;
            color: black;
          }
        }
        .inline-bg-black {
          padding-left: 10px !important;
          padding-right: 10px !important;
          p {
            display: inline;
            background-color: #000;
            box-shadow: 10px 0 0px 0px #000, -10px 0 0px 0px #000;
            padding-top: 1px;
            padding-bottom: 1px;
            color: white;
          }
        }
        :root {
          --primary: #22d2a0;
          --secondary: #fff;
          --background: #fff;
          --green: #1fc11b;
          --yellow: #000;
          --orange: #ff9c55;
          --red: #ff5555;
        }
        .cards {
          background-color: var(--background);
          display: block;
          width: 300px;
          min-height: 90px;
          cursor: pointer;
          padding: 15px;
          margin: calc(50vh - 30px) auto 0 auto;
          border: 3px solid var(--primary);
          box-shadow: 10px -10px 0 -3px var(--yellow), 10px -10px var(--yellow),
            20px -20px 0 -3px var(--yellow), 20px -20px var(--yellow);
        }
        .video-cont {
          video {
            object-fit: cover;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
          }
        }
      `}
    />
  )
}

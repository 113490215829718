import { navigate } from 'gatsby'

import { Sine, Power1, Power2, Power3 } from 'gsap'
import * as Common from '../../common'
import * as Action from 'libs/redux/action'
import { TimelineMax } from 'gsap'
import * as Transition from './'
import * as Libs from 'libs'

let cloneNode
let dispatcher
let cloneTarget
let cloneImageCont
let cloneImageTarget
let cloneTargetObj
let mh
export const TrigerZoomTransition = (to, dispatch, currentTarget, props) => {
  dispatcher = dispatch
  cloneTargetObj = props
  const cont = document.querySelector('html')
  cont.classList.remove('on-overlay')
  cont.classList.add(
    'now-transition',
    'transition-zoom',
    'transition-fade-in',
    `page-load`
  )
  let tlwrapper = document.querySelector('.tl-wrapper')
  const bounds = currentTarget.getBoundingClientRect()

  const cssStyleDeclaration = getComputedStyle(currentTarget, null)

  mh = cssStyleDeclaration.getPropertyValue('max-height')

  cloneTarget = currentTarget.cloneNode(true)
  currentTarget.classList.add('unmount-current-target')

  cloneTarget.style.width = `${bounds.width}px`
  cloneTarget.style.height = `${bounds.height}px`
  cloneTarget.style.display = `block`
  cloneTarget.style.position = `fixed`
  cloneTarget.style.transform = `translate(${bounds.left}px, ${bounds.top}px)`
  cloneTarget.style.backfaceVisibility = `false`
  cloneImageTarget = cloneTarget.querySelector('.gatsby-image-wrapper')
  cloneImageTarget.classList.add('clone-image')
  cloneImageCont = cloneTarget.querySelector('.img-cont')

  cloneNode = tlwrapper.cloneNode(true)

  cloneNode.classList.remove(
    'tl-wrapper',
    'tl-wrapper--mount',
    'tl-wrapper-status--entered'
  )
  cloneNode.classList.add('tl-wrapper', 'tl-wrapper--unmount')

  navigate(to)
  let cloneTargetCont = window.Scrollbar
    ? document.querySelector('.scrollcont')
    : document.querySelector('.tl-wrapper')

  tlwrapper = document.querySelector('.tl-wrapper')
  cloneTargetCont.parentNode.insertBefore(cloneTarget, cloneTargetCont)

  //tlwrapper.parentNode.insertBefore(cloneTarget, tlwrapper)
  tlwrapper.parentNode.insertBefore(cloneNode, tlwrapper)
  Libs.Common.Config.setTransition(
    Transition.TrigerZoomTransition.StartZoomTransition
  )
  //cloneNode.style.top = `${-window.scrollY}px`
  // StartZoomTransition()
}
export const StartZoomTransition = () => {
  exitTransition(cloneNode)
}
const exitTransition = node => {
  const tl = new TimelineMax()
  let currentUnmountTarget = document.querySelector('.unmount-current-target')
  currentUnmountTarget.style.visibility = 'hidden'

  //width: `${windowAspectRatio > 1 ? "100vw" : "auto"} `,
  //height: `${windowAspectRatio < 1 ? "100vh" : "auto"} `,
  const scrollbar = window.Scrollbar

  const topvalue = scrollbar
    ? scrollbar.scrollTop
    : window.scrollY + window.innerHeight / 2

  //const topvalue = window.scrollY + window.innerHeight / 2
  const cont = document.querySelector('html')
  cont.classList.remove('page-load')

  cont.classList.add('init-loaded')
  const ctObj = cloneTargetObj
    ? cloneTargetObj
    : {
        width: '100vw',
        height: `${window.innerHeight}px`,
        x: 0,
        y: 0,
        ease: Power2.easeIn
      }

  if (mh && mh !== 'none') {
    ctObj.maxHeight = '100%'
  }
  tl.to(node, 0.5, {
    opacity: 0,
    scale: 0.9,
    transformOrigin: `center ${topvalue}px`
  })

    .to(cloneTarget, 0.75, ctObj, 0)
    .to(
      cloneImageTarget,
      0.75,
      {
        width: '100%',
        height: '100%',
        left: 0,
        marginLeft: 0,
        ease: Power2.easeOut
      },
      0
    )
  if (cloneImageCont) {
    tl.to(
      cloneImageCont,
      0.75,
      {
        height: '100%',
        ease: Power2.easeOut
      },
      0
    )
  }

  tl.call(function () {
    const entryProjectContainer = document.querySelector(
      '.tl-wrapper--mount .project-container'
    )

    Libs.Common.Func.setType(entryProjectContainer)
    entryTransition()
  })
}
const entryTransition = () => {
  const tl = new TimelineMax({
    onComplete: function () {
      const cont = document.querySelector('html')
      cont.classList.remove(
        'now-transition',
        'transition-zoom',
        'transition-fade-in'
      )
      Libs.Common.Func.excuteTransitionFunc()

      cloneTarget.remove()
      cloneNode.remove()
      const dataset = entryProjectContainer.dataset

      let current = dataset.currentname
        ? { name: dataset.currentname, slug: dataset.current }
        : null
      dispatcher(
        Action.TransitionComplete(
          current,
          dataset.prev,
          dataset.next,
          dataset.categoryname
            ? { name: dataset.categoryname, slug: dataset.categoryslug }
            : null,
          dataset.type
        )
      )
    }
  })
  const entryProjectContainer = document.querySelector(
    '.tl-wrapper--mount .project-container'
  )
  if (typeof window !== `undefined`) {
    const scrollbar = window.Scrollbar
    window.scrollTo(0, 0)

    if (scrollbar) {
      scrollbar.scrollTo(0, 0, 0, {
        callback: () => console.log('done!')
      })
    } else {
    }
  }

  // window.scrollTo(0, 0)

  tl.to(entryProjectContainer, 1, {
    opacity: 1,
    ease: Power3.easeIn
  })
}

import React, { useEffect, useRef, useState } from 'react'

export const getIndexAboutText = lang => {
  return `${
    lang === 'en'
      ? `Howlt Coffee started in 2017 as a fictitious coffee shop that first carries out full-fledged branding, sells goods at online stores, and aims to become a real store using its profits. It was an experimental project that looked for a new method to a successful brand, with a different starting point than the usual design flow. In 2018, a solo exhibition at UltraSuperNewGallery in Harajuku and then he did on holding a pop-up shop in Kyoto. Finaly a real store Howlt Coffee opened on the site of Fukuoka Daimyo Elementary School in Fukuoka. It is a graphical store with painted main graphics and silk-screen typography. We offer a unique coffee shop that combines art, design, and coffee culture.`
      : `Howlt Coffee(ホルトコーヒー)は本格的なブランディングを先行して行い、グッズ等をオンラインストアで販売、その収益で実店舗を目指すという架空のコーヒーショップとして2017年にスタート、通常のデザインフロウとは異なる出発点で、ブランドを成功させるための新しい道筋を探す実験的なプロジェクトでした。原宿のUltraSuperNewGalleryでの個展や京都でのポップアップコーヒーショップなどを経て、2019年5月に福岡大名小学校跡地に実店舗がオープン。ペイントしたメインのグラフィックやシルクスクリーンで制作したタイポグラフィーなどグラフィカルな店舗となっています。厳選した豆を使用したこだわりのコーヒーや季節限定のドリンクに加え、Howlt(ホルト)の商品や世界中からセレクトしたアーティストのグッズなどもホルトコーヒー福岡にて販売。コーヒーの提供だけでは止まらない、アートとデザインとコーヒーカルチャーを融合させたユニークなコーヒーショップを展開しています。`
  }`
}
export const getIndexCoffeeText = lang => {
  return `${
    lang === 'en'
      ? `Howlt Coffee uses an original blend of beans based on Brazilian beans with a chocolate nutty flavor and Colombian beans with a subtle acidity and rich mouthfeel. The two beans are blended and roasted at the green bean stage in order to bring out the best of each bean's characteristics and create a consistent taste. The medium-deep roast gives a deep sweetness like bitter chocolate or cacao in the first sip, followed by a fruity acidity as it cools down, allowing you to enjoy the changes in taste over time. We use beans that have aged to their best state after a few days of roasting, and carefully read the delicate changes in the beans' state to provide our customers with a cup of coffee that they will enjoy even more.`
      : `Howlt Coffee(ホルトコーヒー)ではチョコレート・ナッツのような味わいのブラジル豆と、ほのかな酸味とリッチな口当たりが特徴のコロンビアをベースにしたオリジナルのブレンド豆を使用しています。それぞれの特徴をうまく引き出し合い、味に統一感を出すために、この二つの豆を生豆の段階でブレンドし焙煎しています。中深煎りで、一口目はビターチョコやカカオの様な深みのある甘みを、その後冷めていくうちにフルーティーな酸味に、時間差で感じる味の変化をお楽しみいただけます。焙煎から数日寝かせた一番良い状態にエイジングされた豆を使用し、繊細に変化する豆の状態を丁寧に読みとりながら、お客様により美味しいと感じてもらえる一杯を意識してご提供しております。`
  }`
}

export const getIndexNewMenu = lang => {
  return `${
    lang === 'en'
      ? `October 14 (Sat) - 15 (Sun), 2023 at Howlt Coffee
 `
      : `日本の手しごとでつくられた器や日用品をメインに、産地とおうちを繋ぐというコンセプトのもと展開されているネットショップCRAFT STOREとの期間限定POP UP SHOP
`
  }`
}

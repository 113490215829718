import React, { useEffect, useRef, useState } from 'react'

import { css } from '@emotion/react'
import { StaticImage } from 'gatsby-plugin-image'

import * as Common from '../../common'
import * as Atoms from '../atoms'
import * as Libs from 'libs'

export default props => {
  const { lang, ...rest } = props

  useEffect(() => {
    //Libs.Common.Config.excuteTransition()
  }, [])
  const sectionCss = () => {
    return css`
      width: 100vw;

      display: grid;
      ${Libs.Styles.Mq.moreTab} {
        overflow: hidden;

        grid-template-columns: 1fr 1fr;
      }
      ${Libs.Styles.Mq.lessPab} {
        grid-template-columns: 1fr;
      }
      .text-cont {
        z-index: 1;
        text-align: left;
        position: relative;

        z-index: 10;
        ${Libs.Styles.Mq.moreTab} {
          padding-left: 5vw;
          padding-bottom: 5vw;
        }
        ${Libs.Styles.Mq.lessPab} {
          position: absolute;
          padding-left: 6vw;
          padding-bottom: 10vw;
        }
        .title-cont {
          ${Libs.Styles.Mq.moreTab} {
            max-height: 105vw;
            height: min(100vw, 100vh);
          }
          ${Libs.Styles.Mq.pab} {
            height: min(184vw, 100vh);
          }
          ${Libs.Styles.Mq.sp} {
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
          }

          display: flex;
          .title-innder-cont {
            margin-top: auto;
            ${Libs.Common.Func.getPcSpVwValue('margin-bottom', 50, 32, true)};

            h2 {
              ${Libs.Common.Func.getPcSpVwValue('margin-bottom', 18, 10, true)};
            }
            svg {
              height: max(4.5vw, 4.5vh);
            }
          }
        }
        .desc-cont {
          text-align: left;
          > p {
            ${Libs.Common.Func.getPcSpVwValue('margin-bottom', 18, 18, true)};
          }
          ${Libs.Styles.Mq.moreTab} {
            width: 60%;
          }
          ${Libs.Styles.Mq.lessPab} {
            width: 88vw;
          }
        }

        p {
          ${Libs.Common.Func.getPcSpVwValue('font-size', 18, 18, true)};
          ${Libs.Common.Func.getPcSpVwValue('line-height', 24, 24, true)};
          ${Libs.Styles.Font.Bold()};
          letter-spacing: -0.03em;
        }
      }
      .index-left-section {
        background: #fd978a;

        position: relative;
        ${Libs.Styles.Mq.moreTab} {
          overflow: hidden;
        }
        ${Libs.Styles.Mq.lessPab} {
          height: 100vh;
          height: calc(var(--vh, 1vh) * 100);
          height: calc(var(--vh, 1vh) * 134);
        }
        .hc-logo {
          overflow: hidden;
        }
        .img-cont {
          left: 6vw;
          z-index: 1;

          ${Libs.Styles.Mq.moreTab} {
            position: absolute;
            top: 150px;
            width: 40vw;
            height: min(65vw, 70vh);
            height: calc(min(99vw, 99vh) - 250px - max(7vw, 5vh));
          }
          ${Libs.Styles.Mq.lessPab} {
            position: sticky;
            top: 115px;
            width: 88vw;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100 - 215px - max(5vw, 4vh));
            will-change: transform;
            transform-style: preserve-3d;
          }
          .gatsby-image-wrapper {
            height: 100%;
            img {
              border-radius: 24px;
            }
          }
        }
      }
      .index-right-section {
        position: relative;
        background: #f0b9b5;
        ${Libs.Styles.Mq.lessPab} {
          height: 100vh;
          height: calc(var(--vh, 1vh) * 100);
          height: calc(var(--vh, 1vh) * 128);
        }
        .text-cont {
          ${Libs.Styles.Mq.moreTab} {
            right: 3vw;
            text-align: right;
          }

          .title-innder-cont {
            margin-top: auto;

            ${Libs.Styles.Mq.moreTab} {
              margin-left: auto;
            }
          }
          h1 {
            ${Libs.Common.Func.getPcSpVwValue('font-size', 75, 60, true)};
            ${Libs.Common.Func.getPcSpVwValue('line-height', 75, 60, true)};
            ${Libs.Styles.Font.Bold()}
            letter-spacing:-0.03em;
          }
        }
        .desc-cont {
          margin-left: auto;
          p {
            letter-spacing: 0;
            text-align: justify;
          }
        }
        .img-cont {
          height: 100%;
          position: absolute;
          top: 0px;
          ${Libs.Styles.Mq.lessPab} {
            position: sticky;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            overflow: hidden;
          }
          .gatsby-image-wrapper {
            height: 100%;

            ${Libs.Styles.Mq.lessPab} {
              will-change: transform;
              transform-style: preserve-3d;
            }
          }
        }
      }
      .split-type {
        .line {
          overflow: hidden;
        }
      }
    `
  }

  return (
    <section css={sectionCss}>
      <div className='index-left-section'>
        <div className='text-cont'>
          <div className='title-cont'>
            <div className='title-innder-cont'>
              <h2 className='hc-logo'>
                <Atoms.Logo02 />
              </h2>

              <p className='split-type words'>
                <a
                  className={'insta-link'}
                  href='https://www.instagram.com/howltcoffee/'
                  target='_new'
                >
                  @howltcoffee
                </a>
              </p>
            </div>
          </div>
          <div className='clip-bottom desc-cont'>
            <p>
              2-6-11 DAIMYO CHUO-KU
              <br />
              FUKUOKA JAPAN
              <br />
              810-0041
            </p>
            <p>
              Sat-Sun 1:00 pm — 6:30 pm
              <br />
              Mon-Fri 1:00 pm — 5:00 pm
            </p>
            <p>info@howlt-coffee.com</p>
          </div>
        </div>
        <div className='img-cont'>
          <StaticImage
            onLoad={props => {
              // const cont = document.querySelector('.key-visual')
              // cont.classList.add('loaded')
            }}
            src='../../images/img-feature02.jpg'
            alt='Key Visual'
            placeholder='none'
            layout='constrained'
            loading='eager'
            quality={100}
          />
        </div>
      </div>
      <div className='index-right-section'>
        <Libs.Atoms.TriggerLink
          transition={Libs.Transition.TrigerPageTransition.TrigerPageTransition}
          to={`/${lang}/magazine/craft-store-pop-up-shop/`}
        >
          <div className='text-cont'>
            <div className='title-cont'>
              <div className='title-innder-cont'>
                <Libs.Atoms.H1 className='split-type chars'>
                  POP UP SHOP
                </Libs.Atoms.H1>
                <p className='split-type words'>Howlt Coffee x CRAFT STORE</p>
              </div>
            </div>
            <div className='clip-bottom desc-cont rt-cont'>
              <p className={'rt-content rt-textfield default-text'}>
                {Common.Localize.getIndexNewMenu(lang)}
              </p>

              <Libs.Atoms.TriggerLink
                class='inner-link'
                transition={
                  Libs.Transition.TrigerPageTransition.TrigerPageTransition
                }
                to={`/${lang}/magazine/craft-store-pop-up-shop/`}
              >
                <Atoms.IconParallelogram />
                <p>View More</p>
              </Libs.Atoms.TriggerLink>
            </div>
          </div>
          <div className='img-cont'>
            <StaticImage
              onLoad={props => {
                // const cont = document.querySelector('.key-visual')
                //  cont.classList.add('loaded')
              }}
              src='../../images/feature-img-r.jpg'
              alt='Coffee Beans'
              placeholder='none'
              layout='constrained'
              loading='eager'
              quality={100}
            />
          </div>
        </Libs.Atoms.TriggerLink>
      </div>
    </section>
  )
}

import React from 'react'
import { css } from '@emotion/react'

import * as Modules from '../modules'

import * as Libs from 'libs'

const h3Style = {
  fontPc: {
    fontSize: 14,
    lineHeight: 14
  },
  fontSp: {
    fontSize: 14,
    lineHeight: 14
  },
  positionPc: { space: '0 0 7 0' },
  positionSp: { space: '0 0 15 0' }
}
const pStyle = {
  fontPc: {
    fontFace: 'sansserif',
    fontSize: 25,
    lineHeight: 34,
    letterSpacing: -0.4
  },
  fontSp: {
    fontFace: 'sansserif',
    fontSize: 16,
    lineHeight: 22,
    letterSpacing: -0.4
  }
}

const spanCss = {
  fontPc: {
    fontFace: 'sansserif',
    fontSize: 16,
    lineHeight: 22,
    letterSpacing: -0.4
  },
  fontSp: {
    fontFace: 'sansserif',
    fontSize: 11,
    lineHeight: 11,
    letterSpacing: -0.4
  }
}

const leftContCss = () => css``
const viemapCss = () => css`
  ${Libs.Styles.Mixin.textAncher()}
  padding-top: ${Libs.Common.Func.getVwValue(0.3)}vw;
  display: inline-block;
`
export const InfoAbout = props => {
  return (
    <div className='info-about'>
      <Libs.Atoms.H3 styles={h3Style}>Address</Libs.Atoms.H3>
      <Libs.Atoms.P styles={pStyle}>
        2-6-11 DAIMYO CHUO-KU
        <br />
        FUKUOKA JAPAN
        <br /> 810-0041
      </Libs.Atoms.P>
      <Libs.Atoms.P styles={pStyle}>info@howlt-coffee.com</Libs.Atoms.P>
      <Libs.Atoms.Span styles={pStyle}>
        <a
          css={viemapCss}
          href='https://goo.gl/maps/as8VLteJZRNs4xto6'
          target='_blank'
          rel='noopener noreferrer'
        >
          Google Map
        </a>
      </Libs.Atoms.Span>
    </div>
  )
}

export const InfoAboutHc = props => {
  return (
    <div className='info-hc'>
      <Libs.Atoms.H3 styles={h3Style}>Address</Libs.Atoms.H3>
      <Libs.Atoms.P styles={pStyle}>
        2-6-11 DAIMYO
        <br />
        CHUO-KU FUKUOKA
      </Libs.Atoms.P>
      <Libs.Atoms.Span styles={spanCss}>
        <a
          css={viemapCss}
          href='https://g.page/howltcoffee?share'
          target='_blank'
          rel='noopener noreferrer'
        >
          Google Map
        </a>
      </Libs.Atoms.Span>
    </div>
  )
}

export const InfoHour = props => {
  return (
    <div className='info-hours' css={leftContCss}>
      <Libs.Atoms.H3 styles={h3Style}>Open Hour</Libs.Atoms.H3>
      <Libs.Atoms.P styles={pStyle}>
        Sat-Sun 1:00 PM — 6:30 PM
        <br />
        Mon-Fri 1:00 PM — 5:00 PM
      </Libs.Atoms.P>
    </div>
  )
}

export const InfoContact = props => {
  return (
    <div
      css={css`
        width: 20%;
      `}
    >
      <Libs.Atoms.H3
        css={css`
          white-space: nowrap;
        `}
        styles={h3Style}
      >
        Connect with Us
      </Libs.Atoms.H3>
      <Libs.Atoms.P styles={pStyle}>
        <a href='mailto:info@howlt.com' target='_new'>
          info@howlt.com
        </a>
      </Libs.Atoms.P>
      <Modules.SnsList
        styles={{
          width_pc: 7,
          width_sp: 30,
          positionPc: { space: '7 0 0 0' },
          positionSp: { space: '7 0 0 0' }
        }}
      />
    </div>
  )
}

import React, { useState, useRef, useEffect } from 'react'
import uuid from 'uuid'
import { css } from '@emotion/react'

import * as Libs from 'libs'

const Dropdown = ({ active, items = [], trigerLink, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false)
  const activatorRef = useRef()
  const listRef = useRef()

  const handleClick = () => {
    setIsOpen(!isOpen)
  }
  const contCss = () =>
    css`
      position: relative;
      .activator {
        align-items: center;
        background-color: inherit;
        color: inherit;
        display: flex;
        cursor: pointer;
        line-height: 1;
        max-width: 40px;
        padding: 5px;
        &:after {
          content: '';
          border-bottom: 1px solid #000;
          border-right: 1px solid #000;
          height: 0.5em;
          margin-left: auto;
          margin-top: -5px;
          width: 0.5em;
          transform: rotate(45deg);
        }
      }
      .selected {
        pointer-events: none;
        opacity: 0.3;
      }
      .span-element {
        pointer-events: none;
      }
      .itemList {
        background-color: #ececec;
        border-radius: 8px;
        padding-bottom: 5px;
        color: black;
        display: none;
        margin: 0;
        text-align: center;
        min-width: 40px;
        position: absolute;

        & > div {
          list-style: none;
          margin: 0;

          a,
          a:link {
            display: block;
          }
        }
        &.active {
          display: block;
        }
      }
    `
  const handleClickOutside = event => {
    if (
      listRef.current.contains(event.target) ||
      activatorRef.current.contains(event.target)
    ) {
      return
    }
    setIsOpen(false)
  }
  const linkHandler = (event, item) => {
    setIsOpen(false)

    item.onClick(event)
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // clean up on unmount
    return function cleanup () {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])
  const activeObj = items.find(item => item.slug === active)

  activeObj.selected = 'selected'
  return (
    <div className='wrap' css={contCss} {...rest}>
      <Libs.Atoms.Span
        ref={activatorRef}
        className='activator'
        onClick={handleClick}
        styles={{
          fontPc: { fontFace: 'sunsserif', fontSize: '14' },
          fontSp: { fontFace: 'sunsserif', fontSize: '14' }
        }}
      >
        {activeObj.text}
      </Libs.Atoms.Span>
      <div ref={listRef} className={`itemList ` + (isOpen ? 'active' : null)}>
        {items.map((item, index) => {
          const className = item.slug === active ? 'selected' : ''

          return (
            <div key={index}>
              {trigerLink ? (
                <Libs.Atoms.TriggerLink
                  to={item.url}
                  onClick={event => linkHandler(event, item)}
                  data-key={item.slug}
                >
                  <Libs.Atoms.Span
                    className='span-element'
                    styles={{
                      fontPc: { fontFace: 'sunsserif', fontSize: '14' },
                      fontSp: { fontFace: 'sunsserif', fontSize: '14' }
                    }}
                  >
                    {item.text}
                  </Libs.Atoms.Span>
                </Libs.Atoms.TriggerLink>
              ) : (
                <a href={item.url}>
                  <Libs.Atoms.Span
                    styles={{
                      fontPc: { fontFace: 'sunsserif', fontSize: '14' },
                      fontSp: { fontFace: 'sunsserif', fontSize: '14' }
                    }}
                  >
                    {item.text}
                  </Libs.Atoms.Span>
                </a>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default Dropdown
